import { Injectable } from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {MegaMenuItem, SelectItem} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {NameValuePair} from '../tcis-common/form-elements/form-interfaces';

@Injectable({
  providedIn: 'root'
})
export class DyncSharedDataService {
  private _sharedDataArray = new BehaviorSubject([]);
  sharedDataArray$ = this._sharedDataArray.asObservable();
  private _savedDesks = new BehaviorSubject<MegaMenuItem[]>([]);
  savedDesks$ = this._savedDesks.asObservable();
  private _regions = new BehaviorSubject<SelectItem[]>([]);
  savedRegions$ = this._regions.asObservable();
  private _zones = new BehaviorSubject<SelectItem[]>([]);
  savedZones$ = this._zones.asObservable();
  private _subdivs = new BehaviorSubject<SelectItem[]>([]);
  savedSubdiv$ = this._subdivs.asObservable();
  private _milemarkers = new BehaviorSubject<SelectItem[]>([]);
  savedMilemarkers$ = this._milemarkers.asObservable();

  savedDeskData: Array<MegaMenuItem> = [
    {
      label: 'Desk Name', icon: '',
      items: [
        [
          {
            label: 'Area Specific',
            styleClass: 'dd-menu-item-header',
            items: []
          }
        ],
        [
          {
            label: 'Assigned Territory',
            items: []
          }
        ],
        [
          {
            label: 'Weather Event',
            items: []
          }
        ],
        [
          {
            label: 'Weekend Duty Coverage',
            items: []
          }
        ]
      ]
    }
  ];
  savedRegionData: Array<SelectItem> = [];
  savedZoneData: Array<SelectItem> = [];
  savedSubdiv: Array<SelectItem> = [];
  savedMilemarkers: Array<SelectItem> = [
    {
      label: 'All Milemarkers',
      value: 'all',
    },
    {
      label: '101.50 - 154.00',
      value: '101.50-154.00'
    },
    {
      label: '200.65 - 244.35',
      value: '200.65-244.35'
    }
  ];

  constructor(
    private httpClient: HttpClient
  ) {
    // this.getSharedMenuData()
    //   .pipe(take(1))
    //   .subscribe(sharedMenuData => {
    //     // TODO when api integration is desired, use sharedMenuData[0],
    //     //  this.setRegions(sharedMenuData[1])
    //     this.setSavedDesks(this.savedDeskData);
    //     this.setRegions(this.savedRegionData);
    //     this.setZones(this.savedZoneData);
    //     this.setSubdivs(this.savedSubdiv);
    //     this.setMilemarkers(this.savedMilemarkers);
    //   });
    this.setSavedDesks(this.savedDeskData);
    this.retrieveAPIRegions().subscribe(resp=>{
      this.setRegions(resp);
    });
    this.setZones(this.savedZoneData);
    this.setSubdivs(this.savedSubdiv);
    this.setMilemarkers(this.savedMilemarkers);
  }
  getSharedMenuData(): Observable<any> {
    const allOnInitData = forkJoin([
      this.retrieveAPIDesks(),
      this.retrieveAPIRegions()
    ]);
    return allOnInitData;
  }
  setSharedData(sharedData: Array<any>) {
    this._sharedDataArray.next(sharedData);
  }
  retrieveAPIDesks(): Observable<MegaMenuItem[]> {
    return this.httpClient.get<MegaMenuItem[]>(environment.appConfig.api.desksApi);
  }
  retrieveAPIRegions(): Observable<any> {
    return this.httpClient.get<any[]>(environment.appConfig.api.desksApi + '/dymcregions');
  }
  retrieveAPIZones(region): Observable<SelectItem[]> {
    return this.httpClient.get<SelectItem[]>(environment.appConfig.api.desksApi + '/deskdivision/' + region);
  }
  retrieveAPISubdivs(region,zone): Observable<SelectItem[]> {
    return this.httpClient.get<SelectItem[]>(environment.appConfig.api.desksApi + '/dymcregion/' + region + '/subdivision/' + zone);
  }
  getMilePostPre(region,zone,subDiv){
    const url = environment.appConfig.api.desksApi + '/milepostpre';
    let submitRequestPayLoad={
      'region': region,
      'division' : zone,
      'subDivision':subDiv
    }
    return this.httpClient.put<any>(url, submitRequestPayLoad);
  }
  getMilePostNum(region,zone,subDiv,milePostPost){
    const url = environment.appConfig.api.desksApi + '/milepostnum';
    let submitRequestPayLoad={
      'region': region,
      'division' : zone,
      'subDivision':subDiv,
      'milepostPre': milePostPost
    }
    return this.httpClient.put<any>(url, submitRequestPayLoad);
  }
  retrieveAPIPeople(): Observable<NameValuePair[]> {
    return this.httpClient.get<NameValuePair[]>(environment.appConfig.api.desksApi + '/people');
  }
  /**
   * NON-API APPLICATION DATA
   */
  setSavedDesks(savedDeskData: Array<MegaMenuItem>): void {
    this._savedDesks.next(savedDeskData);
  }
  setRegions(regions: Array<SelectItem>): void {
    this._regions.next(regions);
  }
  setZones(zones: Array<SelectItem>): void {
    this._zones.next(zones);
  }
  setSubdivs(subdivs: Array<SelectItem>): void {
    this._subdivs.next(subdivs);
  }
  setMilemarkers(milemarkers: Array<SelectItem>): void {
    this._milemarkers.next(milemarkers);
  }

  menuClick(event): void {}
}
