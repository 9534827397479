import { Component, Inject, OnInit } from '@angular/core';
import { CSXHeaderModel } from 'csx-ng';
import { MenuItem, Menubar } from 'primeng';
import { Router } from '@angular/router';
import { User, AuthenticationService } from '@csx/security';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AnalyticsService } from '../services/google-analytics.service';
import {AbstractGlobalMapService} from '../tcis-common/tcis-common.module';

@Component({
  selector: 'app-template',
  templateUrl: './app-template.component.html',
  styleUrls: ['./app-template.component.scss']
})
export class AppTemplateComponent implements OnInit {
  items: MenuItem[];
  headerModel: CSXHeaderModel;
  showBanner:boolean = false;
  showCSXHeader:boolean = true;
  notificationTableColumns:any[] = [
    { field: ["tcisTicketId", "trainId", "MILEPOST_NUM_I" , "ASSET_X"], header: "" },
  ];
  notifications: any[];
  selectedNotification: any;
  notificationTimer: any;
  totalNotificationCount: number = 2;
  loggedinTime: Date = new Date();
  url: string = environment.appConfig.url.jumperLog;

  constructor(appUser: User, authService: AuthenticationService, public router:Router, private analytics: AnalyticsService, @Inject('globalMapService') private globalMapService: AbstractGlobalMapService) {
    authService.onUserUpdated.subscribe((user: User) => {
      this.loggedinTime = new Date();
      this.headerModel.userName = user.displayName;
      this.headerModel.userRacf = user.racf;
    });

    this.headerModel = {
            appShortName:"TCIS",
            appLongName:"TCIS",
            userName:appUser ? appUser.displayName : "Unknown",
            userRacf:appUser ? appUser.racf : null
        };
        this.urlChange();
    this.items = [
      {
        label: 'Weekend Duty List',
        command:(event) => { this.router.navigate(['wknd-list/profiles']).then(() => {
          window.location.reload();
          this.urlChange();
        });
      }
      },
       {
        label: 'GIS Dashboard',
        command:(event) => { this.router.navigate(['gis-ng']).then(() => {
          window.location.reload();
         this.urlChange();
        });
      }
      },
      {
       label: 'Dynamic Desk',
       command:(event) => { this.router.navigate(['dync-ng']).then(() => {
        window.location.reload();
        this.urlChange();
      });
      }
     },
     {
      label: 'CallList Dashboard',
      command:(event) => { this.router.navigate(['call-dashboard']).then(() => {
       window.location.reload();
       this.urlChange();
     });
     }
    }, 
   /** {
     label: 'Jumper Log',
     command:(event) => { this.router.navigate(['jumper-log']).then(() => {
      this.urlChange();
    });
    }
   },
   {
    label: 'Call Availability',
    command:(event) => { this.router.navigate(['call-availability']).then(() => {
      window.location.reload();
      this.urlChange();
    });
    }
   } */
    ];
  }

  onNotificationsAcknowledged(): void {
    if (this.notifications) {
      this.notifications.forEach((notification) => {
       //this.setAcknowledge(notification);
      });

     // this.updateNotifications(this.notifications);
    }
  }
  onNotificationSelected(event): void {
    window.open(
      'https://tcis.csx.com/eventframe.cfm?incidentId='+event.data.tcisTicketId,
      '_blank',
      'resizable=yes, scrollbars=yes, titlebar=yes, width=800, height=900, top=10, left=10'
    );
    console.log(event)
  }
  urlChange(){
    this.showBanner = false;
    this.showCSXHeader = true;
    this.headerModel.needsAlert = false;
    if(window.location.href.substr(window.location.href.lastIndexOf('/'),window.location.href.length) === '/profiles'){
      this.headerModel.appLongName = 'Weekend Duty List';
      this.showBanner = false;
    }else if(window.location.href.substr(window.location.href.lastIndexOf('/'),window.location.href.length)=== '/gis-ng'){
      this.headerModel.appLongName = 'GIS Dashboard';
      this.showBanner = true;
      this.headerModel.needsAlert = true;
    }else if(window.location.href.substr(window.location.href.lastIndexOf('/'),window.location.href.length) === '/dync-ng'){
      this.headerModel.appLongName = 'Dynamic Desk';
      this.showBanner = true;
      this.headerModel.needsAlert = true;
    }else if(window.location.href.substr(window.location.href.lastIndexOf('/'),window.location.href.length) === '/call-dashboard'){
      this.headerModel.appLongName = 'CallList Dashboard';
      this.showBanner = false;
      this.headerModel.needsAlert = false;
    }
   /** else if(window.location.href.substr(window.location.href.lastIndexOf('/'),window.location.href.length) === '/jumper-log'){
      this.headerModel.appLongName = 'Jumper Log';
      this.showBanner = false;
      this.showCSXHeader = false;
      this.headerModel.needsAlert = false;
    }/**
    else if(window.location.href.substr(window.location.href.lastIndexOf('/'),window.location.href.length) === '/call-availability'){
      this.headerModel.appLongName = 'Call Avalability';
      this.showBanner = false;
      this.showCSXHeader = false;
      this.headerModel.needsAlert = false;
    } */
  }
  ngOnInit() {
    if (typeof environment.appConfig.googleAnalyticsTrackingId != "undefined" && environment.appConfig.googleAnalyticsTrackingId != null && environment.appConfig.googleAnalyticsTrackingId != '' && environment.appConfig.googleAnalyticsTrackingId !== 'disabled') {
      this.analytics.init();
      this.showBanner = false;
    }
    
    this.globalMapService.gis_notificationObs.subscribe((notification:any[])=>{
      this.notifications=notification["notificationData"];

      this.headerModel.alertCount = this.notifications? this.notifications.length: 0;
    })
    
  }

  onNotificationAcknowledged(rowData){
    console.log(rowData);
  }
  openJumperLog(event){
    window.open(
      this.url,
      '_blank',
      'resizable=yes, scrollbars=yes, titlebar=yes, width=800, height=900, top=10, left=10'
    );
  }
}
