import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import {LoaderService} from '../services/loader.service';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  subscription$: Subscription;
  show = false;
  constructor(private loaderService: LoaderService, private detector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscription$ = this.loaderService.loaderState.
      subscribe((state: boolean) => {
        this.show = state;
        this.detector.detectChanges();
      });
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

}
