import { GISIdentifyLayerConfig, GISPopupTemplateInfo } from "csx-gis";
import { environment } from "../environments/environment";
import { MapConstants } from "../constants/map-constants";

export const MAP_IDENTIFY_MAP_TIPS: GISIdentifyLayerConfig = {
  restEndpoint: environment.appConfig.map.tcis,
  popupTemplates: new Map<string, GISPopupTemplateInfo>([
    [MapConstants.TCIS_INICIDENTS, {} as GISPopupTemplateInfo],
    [MapConstants.BATTERY_MODE, {} as GISPopupTemplateInfo],
    [MapConstants.RR_OWNER, {} as GISPopupTemplateInfo],
    [MapConstants.BATTERY_HEALTH, {} as GISPopupTemplateInfo],
    [MapConstants.LAST_REPORTED, {} as GISPopupTemplateInfo],
    [MapConstants.EOT_VENDORS, {} as GISPopupTemplateInfo],
   
[MapConstants.TRAIN, {} as GISPopupTemplateInfo],
[MapConstants.TRACK_AUTHORITY, {} as GISPopupTemplateInfo],
[MapConstants.SO_CURRENT_DAY, {} as GISPopupTemplateInfo],
  ])
};
