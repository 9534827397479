import { GISSearchSources, Markers } from "csx-gis";
import { environment } from "../environments/environment";

const ZOOM_SCALE = 36112;

export const SEARCH_SOURCES: GISSearchSources = {
  restEndpoint: environment.appConfig.map.tcis,
  proxyEndpoint: environment.appConfig.map.mapProxyUrl,

  // prettier-ignore
  layers: {
    "TCIS Incidents": {
        searchFields: ["INCIDENT_I"],
        suggestionTemplate: "{INCIDENT_I}",
        displayField: "INCIDENT_I",
        outFields: ["INCIDENT_I"],
        name: "TCISIncidents",
        placeholder: "abcd",
        highlightSymbol: Markers.PICTURE,
        zoomScale: ZOOM_SCALE,
      },
       "Train": {
        searchFields: ["TRAIN_I"],
        displayField: "TRAIN_I",
        outFields: ["TRAIN_I"],
        name: "Train",
        placeholder: "Q56789",
        highlightSymbol: Markers.PICTURE,
        zoomScale: ZOOM_SCALE,
      },
    "Switch": {
        searchFields: ["FEATURE_I"],
        suggestionTemplate: "{FEATURE_I}",
        displayField: "FEATURE_I",
        outFields: ["FEATURE_I"],
        name: "Switch",
        placeholder: "24623083",
        highlightSymbol: Markers.PICTURE,
        zoomScale: ZOOM_SCALE,
      },"Road Crossing (Closed)": {
      searchFields: ["XING_C"],
      displayField: "XING_C",
      outFields: ["XING_C"],
      name: "Road Crossing (Closed)",
      placeholder: "831242P",
      highlightSymbol: Markers.POLYGON,
      zoomScale: ZOOM_SCALE,
    },
    "Road Crossing (Open)": {
      searchFields: ["XING_C"],
      displayField: "XING_C",
      outFields: ["XING_C"],
      name: "Road Crossing (Open)",
      placeholder: "155940T",
      highlightSymbol: Markers.POLYGON,
      zoomScale: ZOOM_SCALE,
    }, "Control Point": {
      searchFields: ["NGD_GRAPHIC_DISPLAY_N"],
      displayField: "NGD_GRAPHIC_DISPLAY_N",
      outFields: ["NGD_GRAPHIC_DISPLAY_N"],
      name: "Control Point",
      placeholder: "DINSMORE",
      highlightSymbol: Markers.POLYGON,
      zoomScale: ZOOM_SCALE,
    },
    
    "WIU": {
          searchFields: ["WIU_ADDRESS_I", "WIU_I"],
          suggestionTemplate: "{WIU_ADDRESS_I} : {WIU_I}",
          outFields: ["WIU_ADDRESS_I", "WIU_I"],
          name: "WIU",
          placeholder: "csxt.w.976103:10.wiu",
          highlightSymbol: Markers.PICTURE,
          zoomScale: ZOOM_SCALE,
        },
         "Yard Boundary": {
          searchFields: ["YARD_N", "YARD_TMP_I"],
          suggestionTemplate: "{YARD_N} : {YARD_TMP_I}",
          outFields: ["YARD_N", "YARD_TMP_I"],
          name: "Yard",
          placeholder: "WINCHESTER",
          highlightSymbol: Markers.POLYGON,
        },
        "RR Crossing (Diamond)": {
          searchFields: ["FEATURE_I"],
          displayField: "FEATURE_I",
          outFields: ["FEATURE_I"],
          name: "RR Crossing (Diamond)",
          placeholder: "57161962",
          highlightSymbol: Markers.PICTURE,
          zoomScale: ZOOM_SCALE,
        },
  },
};
