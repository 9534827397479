import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private loaderSubject = new Subject<boolean>();
    loaderState = this.loaderSubject.asObservable();

    private showStack = [];

    constructor() { }

    show() {
        this.showStack.push(0);
        this.loaderSubject.next(true);
    }

    hide() {
        this.showStack.pop();

        if (this.showStack.length === 0) {
            this.loaderSubject.next(false);
        }
    }
}
