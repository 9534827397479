export class MapConstants {
  public static readonly TCIS_INICIDENTS = 'TCIS Incidents';
  public static readonly BATTERY_MODE = 'Battery Mode';
  public static readonly RR_OWNER = 'RR Owner';
  public static readonly BATTERY_HEALTH = 'Battery Health';
  public static readonly LAST_REPORTED = 'Last Reported';
  public static readonly EOT_VENDORS = 'EOT Vendors';
 
  public static readonly TRAIN = 'Train';
  public static readonly TRACK_AUTHORITY = 'Track Authority';
  public static readonly SO_CURRENT_DAY = 'SO - Current Day';

}
