import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class carIncidentService {
  private carIncidentSubject = new Subject<boolean>();
  carIncidentState = this.carIncidentSubject.asObservable();
    constructor(
        private httpClient: HttpClient
      ){}
    retrieveCarIncidents(): Observable<any> {
        return this.httpClient.post<any[]>(environment.appConfig.api.carIncidentAPI,{"defCode":["BS"]});
      }
}
