import { GISMapConfig } from "csx-gis";
import { environment } from "../environments/environment";
import { LAYER_DEFINITIONS } from "./layer-definitions";
import { MAP_IDENTIFY_MAP_TIPS } from "./map-tips";
import { SEARCH_SOURCES } from "./search-sources";
import { MAP_LAYERS } from "./layers";

export const MAP_CONFIG: GISMapConfig = {
  mapServices: MAP_LAYERS,
  searchSources: SEARCH_SOURCES,
  layerDefinitions: LAYER_DEFINITIONS,
  identifyLayers: MAP_IDENTIFY_MAP_TIPS,
  cellLayerUrl: environment.appConfig.map.cell,
 //  weatherToken: environment.appConfig.security.weatherToken
  weatherToken: 'cIyqC83HEnusGvdVFu71nhCCx1w3N5vGKnSEjMXUIeROjSjCO98-WBZbhO5_tDWU'
};
