import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class personSearchService {
  private searchSubject = new Subject<boolean>();
  searchState = this.searchSubject.asObservable();
    constructor(
        private httpClient: HttpClient
      ){}
    retrievePersons(personName): Observable<any> {
        return this.httpClient.get<any[]>(environment.appConfig.api.desksApi + '/deskpersinfo/' + personName);
      }
      pearsonSearchToggler(pearsonSearchToggle){
        this.searchSubject.next(pearsonSearchToggle);
      }
}
