export const appConfigMapping = {
  security: {
    securityDomains: (<any>window).appConfig.security.securityDomains,
    clientId: (<any>window).appConfig.security.clientId,
    autoConfigUrl: (<any>window).appConfig.security.autoConfigUrl,
    weatherToken: (<any>window).appConfig.security.weatherToken,
  },
  api: {
    // You need to update this with the list of your APIs (needs to match app.config.js)
    deptSettingsApi: (<any>window).appConfig.api.deptSettingsApi,
    trainsApi: (<any>window).appConfig.api.trainsApi,
    directionsApi: (<any>window).appConfig.api.directionsApi,
    wkndListApi: (<any>window).appConfig.api.wkndListApi,
    desksApi: (<any>window).appConfig.api.desksApi,
    humpyard:(<any>window).appConfig.api.humpyard,
    carIncidentAPI:(<any>window).appConfig.api.carIncidentAPI,
    callDashboardAPI:(<any>window).appConfig.api.callDashboardAPI
  },
  map: {
    tcis: (<any>window).appConfig.map.tcis,
    railCache: (<any>window).appConfig.map.railCache,
    flimap: (<any>window).appConfig.map.flimap,
    cell: (<any>window).appConfig.map.cell,
    mapProxyUrl: (<any>window).appConfig.map.mapProxyUrl,
  },
  url: {
    jumperLog: (<any>window).appConfig.url.jumperLog,
    callAvailability: (<any>window).appConfig.url.callAvailability
  },
  googleAnalyticsTrackingId: (<any>window).appConfig.googleAnalyticsTrackingId,
  googleAnalyticsURL: (<any>window).appConfig.googleAnalyticsURL
};
