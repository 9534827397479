<div class="tcis-ticket-banner">
  <div class="tcis-ticket-banner-container">
    <ng-container
      *ngIf="!(this.inicidentsbyStatusArr$ | async ) || (this.inicidentsbyStatusArr$ | async ).length === 0">
      <div class="ptc-state-banner-text no-data">No Incident Data Found..</div>
    </ng-container>
    <div *ngFor="let entry of (this.inicidentsbyStatusArr$ | async); let i=index" class="ptc-state-item"
      (click)="showDialog(entry)">
      <div class="ptc-state-banner-text">
        {{ entry.title }} ({{ entry?.inicidents?.length }})
      </div>
    </div>
    <div class="ptc-state-item"
    (click)="showDialog(notificationBanner)">
    <div class="ptc-state-banner-text" *ngIf="notificationBanner?.inicidents?.length>0">
     UOR ({{ notificationBanner?.inicidents?.length }})
    </div>
  </div>
  <div class="ptc-state-item"
    (click)="showCarDialog(carIncidents)">
    <div class="ptc-state-banner-text" *ngIf="notificationBanner && carIncidents?.length>0">
     EOT UOR ({{ carIncidents?.length }})
    </div>
  </div>
  </div>
</div>
<div class="mobile-tcis-ticket-banner">
  <div style="min-width: inherit; margin: 6px;">
      <i class="pi pi-angle-left " style="padding: 10px;" (click)="previousValue()"></i>
      <ng-container
        *ngIf="(!(this.inicidentsbyStatusArr$ | async ) || (this.inicidentsbyStatusArr$ | async ).length === 0); else dataTemplate">
        <span class="ptc-state-banner-text no-data">No Incident Data Found..</span>
      </ng-container>
      <ng-template #dataTemplate>
        <span style="border: 1px solid black; padding: 10px;" (click)="showDialog(this.inicidentsbyStatusArr[this.index])">
          {{(this.inicidentsbyStatusArr)[this.index].title}}
        </span>
      </ng-template>
      <i class="pi pi-angle-right"  style="padding: 10px" (click)="nextValue()"></i>
  </div>
</div>
<div>
  <p-dialog [(visible)]="displayTcisTicketDialog"  [modal]="false" 
     [draggable]="true" [resizable]="true" styleClass="x-modal-complex diaWH" [style]="{ width: '35vw', height: '594px' }">
    <p-header>{{ selectedTickets?.title }} Tickets</p-header>

    <div class="data-container" style="padding: 10px">
      <p-table #dt [value]="selectedTickets?.inicidents" [scrollable]="true" scrollHeight="47vh" selectionMode="single"
        [(selection)]="selectedTicket" (onRowSelect)="onTicketSelected()"
        [globalFilterFields]="['INCIDENT_I','ASSET_TYPE_X','ASSET_X','SUBDIVISION_N']">
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <p-button type="button" pRipple icon="pi pi-download" (click)="exportExcel()" class="p-button-success mr-2"  pTooltip="XLS" tooltipPosition="bottom"></p-button>
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Search keyword" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-ticket>
          <tr>
            <th [pSortableColumn]="'INCIDENT_I'">
              Ticket ID
              <p-sortIcon [field]="'INCIDENT_I'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'ENTERED_D'">
              Opened Date
              <p-sortIcon [field]="'ENTERED_D'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'ASSET_TYPE_X'">
              Type
              <p-sortIcon [field]="'ASSET_TYPE_X'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'ASSET_X'">
              Asset
              <p-sortIcon [field]="'ASSET_X'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'SUBDIVISION_N'">
              Subdivision Name
              <p-sortIcon [field]="'SUBDIVISION_N'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'INCIDENT_HEADER_I'">
              Event ID
              <p-sortIcon [field]="'INCIDENT_HEADER_I'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'IRI_I'">
              IRI
              <p-sortIcon [field]="'IRI_I'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'MTTR_Q'">
              Time to Repair
              <p-sortIcon [field]="'MTTR_Q'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'ETAMINUTES_M'" *ngIf="selectedTickets?.title === 'Dispatched'">
              ETA
              <p-sortIcon [field]="'ETAMINUTES_M'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'FIRST_N'" *ngIf="selectedTickets?.title === 'Dispatched'">
              Name
              <p-sortIcon [field]="'FIRST_N'"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-ticket>
          <tr [pSelectableRow]="ticket">
            <td>{{ ticket?.INCIDENT_I }}</td>
            <td>{{ ticket?.ENTERED_D | date:'MM-dd-yyyy HH:mm:ss' }}</td>
            <td>{{ ticket?.ASSET_TYPE_X }}</td>
            <td>{{ ticket?.ASSET_X }}</td>
            <td>{{ ticket?.SUBDIVISION_N }}</td>
            <td>{{ ticket?.INCIDENT_HEADER_I }}</td>
            <td>{{ ticket?.IRI_I }}</td>
            <td>{{ ticket?.MTTR_Q?.toFixed(2) }}</td>
            <td *ngIf="selectedTickets?.title === 'Dispatched'">{{ ticket?.ETAMINUTES_M }}</td>
            <td *ngIf="selectedTickets?.title === 'Dispatched'">{{ ticket?.FIRST_N }} {{ ticket?.LAST_N }}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2">No tickets found. Great job team!</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <!--
    <div style="position:fixed;float:">
      <i class="pi pi-arrow-right" style="color: grey; float: right; font-size: 1.1em; transform: rotate(45deg)"></i>
    </div>
    -->
  </p-dialog>
</div>






<div>
  <p-dialog [(visible)]="displayCarIncidentDialog" [modal]="false"
    resizable="false" [draggable]="true" styleClass="x-modal-complex" [style]="{ width: '35vw', height: '594px' }">
    <p-header>Car Incidents</p-header>

    <div class="data-container" style="padding: 10px">
      <p-table #dtc [value]="carIncidents" [scrollable]="true" scrollHeight="47vh" selectionMode="single"
        [(selection)]="selectedCarIncident" (onRowSelect)="onCarIncidentSelected()"
        [globalFilterFields]="['incidentId']">
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <p-button type="button" pRipple icon="pi pi-download" (click)="exportCarExcel()" class="p-button-success mr-2"  pTooltip="XLS" tooltipPosition="bottom"></p-button>
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')"
                placeholder="Search keyword" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-ticket>
          <tr>
            <th [pSortableColumn]="'incidentId'">
              Ticket ID
              <p-sortIcon [field]="'incidentId'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'carDescr'">
              Car Description
              <p-sortIcon [field]="'carDescr'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'repDT'">
              Reported Date
              <p-sortIcon [field]="'repDT'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'lastT0chdRepoByRacf'">
              Last Reported By
              <p-sortIcon [field]="'lastT0chdRepoByRacf'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'lastT0chdLocName'">
              Last Reported Location
              <p-sortIcon [field]="'lastT0chdLocName'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'addInfo'">
              Additional Info
              <p-sortIcon [field]="'addInfo'"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-ticket>
          <tr [pSelectableRow]="ticket">
            <td>{{ ticket?.incidentId }}</td>
            <td>{{ ticket?.carDescr }}</td>
            <td>{{ ticket?.repDT | date:'MM-dd-yyyy HH:mm:ss' }}</td>
            <td>{{ ticket?.lastT0chdRepoByRacf }}</td>
            <td>{{ ticket?.lastT0chdLocName }}</td>
            <td>{{ ticket?.addInfo }}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2">No car incidents found. Great job team!</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <!--
    <div style="position:fixed;float:">
      <i class="pi pi-arrow-right" style="color: grey; float: right; font-size: 1.1em; transform: rotate(45deg)"></i>
    </div>
    -->
  </p-dialog>
</div>