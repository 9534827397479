import {Inject, Injectable} from '@angular/core';
import {EsriMapComponent, GISMapConfig, MapConfigInjectionToken} from 'csx-gis';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, from, Observable, ReplaySubject, Subject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '@csx/security';

export interface IncidentData {
  inicidentsbyStatusArr: Array<any>;
  inicidentsbyTypeArr:  Array<any>;
  inicidentsbyAssetTypeArr: Array<any>;
  inicidentsbyAssetCrossingTypeArr: Array<any>;
  inicidentsbyAssetDDTypeArr: Array<any>;
  inicidentsbyAssetCITypeArr: Array<any>;
}

export interface LayerQuery {
  dateFilter?: any;
  inicidentStatusStr: string;
  department: string;
  region: string;
  subDiv: string;
  zone?:string;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalMapService {
  featureLayerProperties = <__esri.FeatureLayerProperties>{
    id: 'tcis-inicident-layer',
    url: environment.appConfig.map.tcis + '/0',
    visible: false,
    outFields: ['*'],
    where: "1=1",
    f: 'json',
    orderByFields:'CREATE_D Desc'
  };

  featureTrainLayerProperties = <__esri.FeatureLayerProperties>{
    id: 'tcis-train-layer',
    url: environment.appConfig.map.tcis + '/10',
    visible: true,
    outFields: ['*'],
    f: 'json',
  };

  featureTrackAuthLayerProperties = <__esri.FeatureLayerProperties>{
    id: 'tcis-track-Authority-layer',
    url: environment.appConfig.map.tcis + '/39',
    visible: true,
    outFields: ['*'],
    f: 'json',
  };

    featureSlowOrderLayerProperties = <__esri.FeatureLayerProperties>{
    id: 'tcis-slow-order-layer',
    url: environment.appConfig.map.tcis + '/41',
    visible: true,
    outFields: ['*'],
    f: 'json',
     };
  gis_tcisLayer: __esri.FeatureLayer;

  GIS_TRAIN_LAYER: __esri.FeatureLayer;
  GIS_TRACK_AUTH_LAYER: __esri.FeatureLayer;
  GIS_SLOW_ORDER_LAYER: __esri.FeatureLayer;
  gis_featureLayer: typeof __esri.FeatureLayer;
  gis_query: typeof __esri.Query;

  dync_tcisLayer: __esri.FeatureLayer;
  dync_featureLayer: typeof __esri.FeatureLayer;
  dync_query: typeof __esri.Query;

  trainsbyPerformance: any;
   trainsbyPerformanceArr: any = [];

  gis_mapInstance: EsriMapComponent;
  dync_mapInstance: EsriMapComponent;
  libId:'gis'|'dync';

  private _gis_mapIsReady = new BehaviorSubject<any>({ init: false });
  public gis_mapIsReady$ = this._gis_mapIsReady.asObservable();

  private _dync_mapIsReady = new BehaviorSubject<any>({ init: false });
  public dync_mapIsReady$ = this._dync_mapIsReady.asObservable();

  private _gis_inicidentbyStatusObs = new ReplaySubject<any>();
  private _gis_incidentData = new ReplaySubject<IncidentData>();
  private _gis_featureData = new ReplaySubject<any>();
  private _gis_rightPanelList = new ReplaySubject<any>();
  private _gis_rightPanelInicident = new ReplaySubject<any>();
  public gis_inicidentbyStatusObs = this._gis_inicidentbyStatusObs.asObservable();
  public gis_incidentData = this._gis_incidentData.asObservable();
  public gis_featureData = this._gis_featureData.asObservable();
  public gis_rightPanelList = this._gis_rightPanelList.asObservable();
  public gis_rightPanelInicident = this._gis_rightPanelInicident.asObservable();

  private _gis_notificationObs =   new ReplaySubject<any>();
  public gis_notificationObs = this._gis_notificationObs.asObservable();

  private _dync_inicidentbyStatusObs = new BehaviorSubject<number>(0);
  private _dync_incidentData = new ReplaySubject<IncidentData>();
  private _dync_featureData = new ReplaySubject<any>();
  private _dync_rightPanelList = new ReplaySubject<any>();
  private _dync_rightPanelInicident = new ReplaySubject<any>();
  public dync_inicidentbyStatusObs = this._dync_inicidentbyStatusObs.asObservable();
  public dync_incidentData = this._dync_incidentData.asObservable();
  public dync_featureData = this._dync_featureData.asObservable();
  public dync_rightPanelList = this._dync_rightPanelList.asObservable();
  public dync_rightPanelInicident = this._dync_rightPanelList.asObservable();

  private _trainbyPerformanceObs = new BehaviorSubject<number>(0);
  public gis_trainbyPerformanceObs = this._trainbyPerformanceObs.asObservable();

  private _humpYardObs = new BehaviorSubject<number>(0);
  public gis_humpYardObs = this._humpYardObs.asObservable();
  queryObj = { inicidentStatusStr: '', department: '', region: '',zone : '', subDiv: '' };

  private  inicidentStatuses = [
    { title: 'Active', value: 'active' },
    { title: 'CIS', value: 'cleared-in-service' },
    { title: 'Deferred', value: 'deferred' },
    { title: 'Dispatched', value: 'dispatched' },
    { title: 'Closed', value: 'closed' },
    { title: 'Escalated', value: 'escalated' },
    { title: 'Informational', value: 'informational' },
    { title: 'Pending', value: 'pending' },
    { title: 'Resolved', value: 'resolved' },
    { title: 'Transferred', value: 'transferred' },
    { title: 'Unassigned', value: 'unassigned' },
  ];

  constructor(
    @Inject(MapConfigInjectionToken) public mapConfig: GISMapConfig,
    private http: HttpClient,
    private user: User
  ) { }
  getUserDept(racf){
    let user_dept = environment.appConfig.api.deptSettingsApi + '/user/' + racf;
    return  this.http.get<any>(user_dept);
  }
  setMapInstance(mapInstance, libId: 'gis'|'dync'): void {
    if (mapInstance !== null) {
      if (libId && libId === 'gis') {
        this.gis_mapInstance = mapInstance;
        this.libId = libId;
        this._gis_mapIsReady.next({ init: true });
        this.gis_tcisLayer = new this.gis_featureLayer(this.featureLayerProperties);
        const featureData = from(this.gis_tcisLayer.queryFeatures());
        this.extractMapTotals(featureData, libId);
        // data for tcis trains
        this.GIS_TRAIN_LAYER = new this.gis_featureLayer(this.featureTrainLayerProperties);
        const featurTrainData = from(this.GIS_TRAIN_LAYER.queryFeatures());
        this.extractTrainTotals(featurTrainData);
        // data for track authority
        this.GIS_TRACK_AUTH_LAYER = new this.gis_featureLayer(this.featureTrackAuthLayerProperties);
        const featurTrackAuthData = from(this.GIS_TRACK_AUTH_LAYER.queryFeatures());
        this.extractTrackAuth(featurTrackAuthData);
         // data for slow order
        this.GIS_SLOW_ORDER_LAYER = new this.gis_featureLayer(this.featureSlowOrderLayerProperties);
        const featurSlowOrderData = from(this.GIS_SLOW_ORDER_LAYER.queryFeatures());
        this.extractSlowOrder(featurSlowOrderData);
        this.gis_mapInstance.getMapView().constraints = {
          minZoom: 5,
          maxZoom: 21,
        };
      }
      if (libId && libId === 'dync') {
        this.getUserDept(this.user.racf).subscribe(resp=>{
          this.dync_mapInstance = mapInstance;
          this.dync_tcisLayer = new this.dync_featureLayer(this.featureLayerProperties);
          if(resp.departmentName === 'Communications' || resp.departmentName === 'Engineering' || resp.departmentName === 'Signals'){
            this.queryObj.department = '\'' + resp.departmentName + '\'';
            const query = this.getLayerQueryString(this.queryObj);
            let queryobj = this.dync_tcisLayer.createQuery();
            queryobj.where = query;
            const featureData = from(this.dync_tcisLayer.queryFeatures(queryobj));
            this.extractMapTotals(featureData, libId);
          }
          else{
            const featureData = from(this.dync_tcisLayer.queryFeatures());
            this.extractMapTotals(featureData, libId);
          }
          this._dync_mapIsReady.next({ init: true });
          this.dync_mapInstance.getMapView().constraints = {
            minZoom: 5,
            maxZoom: 21,
          };
        });
      }
    }
  }
  onMapClick(mapElemList){
    console.log(mapElemList)
    let inicidents  = [];
    for(let elem in  mapElemList){
      let inicident = mapElemList[elem]["attributes"];
      inicident.geometry = mapElemList[elem]["geometry"];
      inicident.layer = mapElemList[elem]["layer"];
      inicidents.push(inicident)
    }
    this.listSelected({
      displayHeader:'Incident Information',
      selectedInicidentList:{
        inicidents:inicidents,
        title:'Detail Display'
      }
    },'gis')
  }
  onMapClickDync(mapElemList){
    let inicidents  = [];
    for(let elem in  mapElemList){
      let inicident = mapElemList[elem]["attributes"];
      inicident.geometry = mapElemList[elem]["geometry"];
      inicident.layer = mapElemList[elem]["layer"];
      inicidents.push(inicident)
    }
    this.listSelected({
      displayHeader:'Incident Information',
      selectedInicidentList:{
        inicidents:inicidents,
        title:'Detail Display'
      }
    },'dync')
  }

  publishIncidentData(incidentData, libId: 'gis'|'dync'): void {
    if (libId && libId === 'gis') {
      const gisIncidentData = this.filterLibSpecificIncidentData(incidentData, libId);
      this._gis_inicidentbyStatusObs.next(gisIncidentData.inicidentsbyStatusArr);
      this._gis_incidentData.next(gisIncidentData);
    }
    if (libId && libId === 'dync') {
      const dyncIncidentData = this.filterLibSpecificIncidentData(incidentData, libId);
      this._dync_inicidentbyStatusObs.next(dyncIncidentData.inicidentsbyStatusArr);
      this._dync_incidentData.next(dyncIncidentData);
    }
  }

  extractMapTotals(featureData, libId: 'gis'|'dync'): void {
   
    featureData.pipe(take(1)).subscribe((response) => {
      if (libId === 'gis') {
        this._gis_featureData.next(response);
      }
      if (libId === 'dync') {
        this._dync_featureData.next(response);
      }
      const inicidentsbyStatus = {};
      const inicidentsbyStatusArr = [];
      const inicidentsbyAssetType = {};
      const inicidentsbyAssetTypeArr = [];
      const inicidentsbyAssetCrossingTypeArr = [];
      const inicidentsbyAssetDDTypeArr = [];
      let inicidentsbyAssetCITypeArr = [];
      const inicidentsbyType = {};
      const inicidentsbyTypeArr = [];
      
      const notificationData = [];
      const notificationBannerData = [];
      // let trainLayer = this.gis_mapInstance.getLayerByName('Train');
      // trainLayer.visible = true;
      // let mobileAassetLayer = this.gis_mapInstance.getLayerByName('Mobile Asset');
      // mobileAassetLayer.visible = true;
      if (libId === 'dync') {
       let trainLayer = this.dync_mapInstance.getLayerByName('Train');
       trainLayer.visible = false;
       let engineeringMilepostLayer = this.dync_mapInstance.getLayerByName('Engineering Milepost');
       engineeringMilepostLayer.visible = false;
      }else {
       let trainLayer = this.gis_mapInstance.getLayerByName('Train');
       trainLayer.visible = false;
       let engineeringMilepostLayer = this.gis_mapInstance.getLayerByName('Engineering Milepost');
       engineeringMilepostLayer.visible = false;
      }
      response.features.forEach((inicident) => {
        const attributes = inicident.attributes;
        attributes.geometry = inicident.geometry;
        attributes.layer = inicident.layer;

        // INICIDENT status based data
        let inicidentStatus = attributes.INCIDENT_STATUS_X;
        inicidentStatus = inicidentStatus
          .toLowerCase()
          .replaceAll(' ', '-');
        if (!inicidentsbyStatus[inicidentStatus]) {
          inicidentsbyStatus[inicidentStatus] = {
            title: attributes.INCIDENT_STATUS_X,
            inicidents: [],
          };
        }
        inicidentsbyStatus[inicidentStatus]['inicidents'].push(
          attributes
        );

         //filter prority incidents
          if(["Closed"].indexOf(attributes.INCIDENT_STATUS_X) !== -1){
      //  if(["Resolved"].indexOf(attributes.INCIDENT_STATUS_X) !== -1){
            let notification = { enforcementId:attributes.INCIDENT_I,
            acknowledgeDate: attributes.CREATE_D,
            acknowledgeUser: "acknowledgeUser",
            tcisTicketId: attributes.INCIDENT_I,
            trainId:attributes.INCIDENT_I,
            MILEPOST_NUM_I: attributes.MILEPOST_NUM_I,
            ASSET_X: attributes.ASSET_X}
          //notificationData.push(notification)
            notificationBannerData.push(attributes)
          }


          if( attributes.PRIORITY_I == 0 ){
        //  if(["Resolved"].indexOf(attributes.INCIDENT_STATUS_X) !== -1){
              let notification = { enforcementId:attributes.INCIDENT_I,
              acknowledgeDate: attributes.CREATE_D,
              acknowledgeUser: "acknowledgeUser",
              tcisTicketId: attributes.INCIDENT_I,
              trainId:attributes.INCIDENT_I,
              MILEPOST_NUM_I: attributes.MILEPOST_NUM_I,
              ASSET_X: attributes.ASSET_X}
              notificationData.push(notification)
              notificationBannerData.push(attributes)
            }


          
     //   }
        
        // INICIDENT type based data

        let inicidentType = attributes.INCIDENT_TYPE_X;
        inicidentType = inicidentType.toLowerCase().replaceAll(' ', '-');
        if (!inicidentsbyType[inicidentType]) {
          inicidentsbyType[inicidentType] = {
            title: attributes.INCIDENT_TYPE_X,
            inicidents: [],
          };
        }
        inicidentsbyType[inicidentType]['inicidents'].push(attributes);

        // Asset type based data

        let assetType = attributes.ASSET_TYPE_X;
        assetType = assetType.toLowerCase().replaceAll(' ', '-');
        if (!inicidentsbyAssetType[assetType]) {
          inicidentsbyAssetType[assetType] = {
            title: attributes.ASSET_TYPE_X,
            inicidents: [],
          };
        }
        inicidentsbyAssetType[assetType]['inicidents'].push(
          attributes
        );
      });
      console.log(inicidentsbyAssetType);
      console.log(notificationData);
      // publish data
      this._gis_notificationObs.next({notificationBannerData:notificationBannerData,notificationData:notificationData});

      // to add un fetched status values to zero
      this.inicidentStatuses.forEach((obj) => {
        if (!inicidentsbyStatus[obj.value]) {
          inicidentsbyStatus[obj.value] = {
            title: obj.title,
            inicidents: [],
          };
        }
      });

      // INICIDENT status based data
      const keys = Object.keys(inicidentsbyStatus);
      keys.sort().forEach((key) => {
        console.log(key)
        if(key !== 'closed')
        inicidentsbyStatusArr.push(inicidentsbyStatus[key]);
      });

      // INICIDENT type based data
      const typeKeys = Object.keys(inicidentsbyType);
      typeKeys.sort().forEach((key) => {
        inicidentsbyTypeArr.push(inicidentsbyType[key]);
      });

      // Asset type based data
      const asssetTypeKeys = Object.keys(inicidentsbyAssetType);
      asssetTypeKeys.sort().forEach((key) => {
        inicidentsbyAssetTypeArr.push(inicidentsbyAssetType[key]);
      });

      // Asset type crossing based data
      const inicidentsbyAsseCrossingType =
        inicidentsbyAssetType['crossing'] || {};
      const asetTypeCrossing =
        inicidentsbyAsseCrossingType['inicidents'] || [];
      const inicidentsCrossingbyType = {};
      asetTypeCrossing.forEach((inicident) => {
        let inicidentType = inicident.INCIDENT_TYPE_X;
        inicidentType = inicidentType.toLowerCase().replaceAll(' ', '-');
        if (!inicidentsCrossingbyType[inicidentType]) {
          inicidentsCrossingbyType[inicidentType] = {
            title: inicident.INCIDENT_TYPE_X,
            inicidents: [],
          };
        }
        inicidentsCrossingbyType[inicidentType]['inicidents'].push(
          inicident
        );
        
      });

      console.log(inicidentsCrossingbyType)
      const crossingKeys = Object.keys(inicidentsCrossingbyType);
      crossingKeys.sort().forEach((key) => {
        inicidentsbyAssetCrossingTypeArr.push(
          inicidentsCrossingbyType[key]
        );
      });

      // Asset type defect detectors based data
      const inicidentsbyAsseDDType =
        inicidentsbyAssetType['dd-defect-detector'] || {};
      const asetTypeDD = inicidentsbyAsseDDType['inicidents'] || [];
      const inicidentsDDbyType = {};
      asetTypeDD.forEach((inicident) => {
        let inicidentType = inicident.INCIDENT_TYPE_X;
        inicidentType = inicidentType.toLowerCase().replaceAll(' ', '-');
        if (!inicidentsDDbyType[inicidentType]) {
          inicidentsDDbyType[inicidentType] = {
            title: inicident.INCIDENT_TYPE_X,
            inicidents: [],
          };
        }
        inicidentsDDbyType[inicidentType]['inicidents'].push(inicident);
      });

      const ddKeys = Object.keys(inicidentsDDbyType);
      ddKeys.sort().forEach((key) => {
        inicidentsbyAssetDDTypeArr.push(inicidentsDDbyType[key]);
      });
// Asset type circuit issues based data
      const inicidentsbyAsseCircuitIssueType =
        inicidentsbyAssetType['Circuit Issues'] || {};


      const url = environment.appConfig.api.humpyard;
      this.http.get(url).subscribe((response)=>{
        console.log(response);
      this.humpYardChange(response);
      });

      inicidentsbyAssetCITypeArr.push( inicidentsbyAssetType['ptc-data-leased-circuit-sat']);
      inicidentsbyAssetCITypeArr.push( inicidentsbyAssetType['ptc-data-leased-circuit-mpls']);
      inicidentsbyAssetCITypeArr.push( inicidentsbyAssetType['ngd-voice-leased-circuit-mpls']);
      inicidentsbyAssetCITypeArr.push( inicidentsbyAssetType['ngd-data-leased-circuit-mpls']);
      inicidentsbyAssetCITypeArr.push( inicidentsbyAssetType['ngd-voice-digi']);
      inicidentsbyAssetCITypeArr.push( inicidentsbyAssetType['ngd-yard-leased-circuit-mpls']);
      inicidentsbyAssetCITypeArr.push( inicidentsbyAssetType['ngd-data-digi']);
      inicidentsbyAssetCITypeArr.push( inicidentsbyAssetType['ngd-data-leased-circuit-sat']);
      inicidentsbyAssetCITypeArr.push( inicidentsbyAssetType['ptc-data-digi']);
      inicidentsbyAssetCITypeArr = inicidentsbyAssetCITypeArr.filter(element=> {
        return element != undefined
      })
      // fix title of Cleared in Service for Display purposes
      if (inicidentsbyStatusArr[0].title === 'Cleared In Service') {
        inicidentsbyStatusArr[0].title = 'CIS';
      }
      this.publishIncidentData({
        inicidentsbyStatusArr: inicidentsbyStatusArr,
        inicidentsbyTypeArr: inicidentsbyTypeArr,
        inicidentsbyAssetTypeArr: inicidentsbyAssetTypeArr,
        inicidentsbyAssetCrossingTypeArr: inicidentsbyAssetCrossingTypeArr,
        inicidentsbyAssetDDTypeArr: inicidentsbyAssetDDTypeArr,
        inicidentsbyAssetCITypeArr: inicidentsbyAssetCITypeArr
      }, libId);
    });
  }

  filterLibSpecificIncidentData(incidentData, libId: 'gis'|'dync'): any {
    // if (libId === 'dync') {
    //   const {inicidentsbyStatusArr} = incidentData;
    //   const filterTo = ['Active', 'Dispatched', 'CIS', 'Closed', 'EOT Delays'];
    //   // filter StatusArray by array of desired titles
    //   const filteredByStatusArr = _.filter(inicidentsbyStatusArr,
    //     (row) => _.some(
    //       filterTo, (f) => row.title === f
    //     )
    //   );
    //   delete incidentData.inicidentsbyStatusArr;
    //   incidentData.inicidentsbyStatusArr = filteredByStatusArr;
    // }
    return incidentData;
  }

  listSelected(incidentList, libId: 'gis'|'dync'): void {
    if (libId === 'gis') {
      this._gis_rightPanelList.next(incidentList);
    }
    if (libId === 'dync') {
      this._dync_rightPanelList.next(incidentList);
    }
  }
   inicidentSelected(incident, libId: 'gis'|'dync'): void {
    if (libId === 'gis') {
      this._gis_rightPanelInicident.next(incident);
    }
    if (libId === 'dync') {
      this._dync_rightPanelList.next(incident);
    }
  }

  getLayerQueryString(queryObj: LayerQuery): string {
    let query = '';
    if (queryObj.inicidentStatusStr.length > 0) {
      query = 'INCIDENT_STATUS_X in (' + queryObj.inicidentStatusStr + ')';
    }
    if (queryObj.department.length > 0) {
      if (query.length > 0) {
        query += 'AND DEPARTMENT_N in (' + queryObj.department + ')';
      } else {
        query += 'DEPARTMENT_N in (' + queryObj.department + ')';
      }
    }
    if (queryObj.region.length > 0) {
      if (query.length > 0) {
        query += 'AND REGION_N in (' + queryObj.region + ')';
      } else {
        query += 'REGION_N in (' + queryObj.region + ')';
      }
    }
    if(queryObj?.zone?.length > 0){
      if (query.length > 0) {
        query += 'AND DIVISION_N in (' + queryObj.zone + ')';
      } else {
        query += 'DIVISION_N in (' + queryObj.zone + ')';
      }
    }
    if (queryObj?.subDiv?.length > 2) {
      if (query.length > 0) {
        query += 'AND SUBDIVISION_N in (' + queryObj.subDiv + ')';
      } else {
        query += 'SUBDIVISION_N in (' + queryObj.subDiv + ')';
      }
    }

    if(queryObj?.dateFilter?.last24Hrs || queryObj?.dateFilter?.last48Hrs){
      var dateStr = ''
      if(queryObj?.dateFilter?.last24Hrs){
       var last24HrsDate = new Date(Date.now() - (24*60*60*1000));
       dateStr = last24HrsDate.getFullYear()+'-'+(last24HrsDate.getMonth()+1)+'-'+last24HrsDate.getDate()+
      ' '+last24HrsDate.getHours()+':'+last24HrsDate.getMinutes()+':'+last24HrsDate.getSeconds()

      }else if(queryObj?.dateFilter?.last48Hrs){
      var last24HrsDate = new Date(Date.now() - (48*60*60*1000));
      dateStr = last24HrsDate.getFullYear()+'-'+(last24HrsDate.getMonth()+1)+'-'+last24HrsDate.getDate()+
      ' '+last24HrsDate.getHours()+':'+last24HrsDate.getMinutes()+':'+last24HrsDate.getSeconds()
      }
      if (query.length > 0) {
        query += 'AND CREATE_D > DATE\'' + dateStr + '\'';
      } else {
        query += 'CREATE_D > DATE\'' +  dateStr  + '\'';
      }
    }
    if (query.length === 0) {
      query = '1=1';
    }
    return query;
  }

  updateBanner(query: string): void {
    let featureData:any;
      this.gis_tcisLayer = new this.gis_featureLayer(this.featureLayerProperties);
      let queryObj = this.gis_tcisLayer.createQuery();
      queryObj.where = query;
      featureData = from(this.gis_tcisLayer.queryFeatures(queryObj));
      this.extractMapTotals(featureData, this.libId);
  }
  
  updateBannerDync(query: string): void {
    let featureData:any;
    this.dync_tcisLayer = new this.dync_featureLayer(this.featureLayerProperties);
    let queryObj = this.dync_tcisLayer.createQuery();
    queryObj.where = query;
    featureData = from(this.dync_tcisLayer.queryFeatures(queryObj));
    this.extractMapTotals(featureData, 'dync');
  }

 extractTrackAuth(featurTrackAuthData) {
  featurTrackAuthData.subscribe((response) => {

    const trainsbyTrackAuthArr = [];
    response.features.forEach((trackAuth) => {
      const attributes = trackAuth.attributes;
      attributes.geometry = trackAuth.geometry;
      attributes.layer = trackAuth.layer;
      trainsbyTrackAuthArr.push(attributes);
    });

     // trin performance based data
     this.trainsbyPerformanceArr.push({title: 'Track Authority', inicidents: trainsbyTrackAuthArr});
     if (this.trainsbyPerformanceArr.length === 4) {
     this.trainPerformanceChange(this.trainsbyPerformanceArr);
    }

  });
}

 extractSlowOrder(featurSlowOrderData): void {
  featurSlowOrderData.subscribe((response) => {

    const trainsbySlowOrderhArr = [];
    response.features.forEach((trackAuth) => {
      const attributes = trackAuth.attributes;
      attributes.geometry = trackAuth.geometry;
      attributes.layer = trackAuth.layer;
      trainsbySlowOrderhArr.push(attributes);
    });

     // trin performance based data
     this.trainsbyPerformanceArr.push({title: 'Slow Order', inicidents: trainsbySlowOrderhArr});
     if (this.trainsbyPerformanceArr.length === 4) {
     this.trainPerformanceChange(this.trainsbyPerformanceArr);
     }
  });
}

extractTrainTotals(featurTrainData) {
  featurTrainData.subscribe((response) => {
   this.trainsbyPerformance = {};
    response.features.forEach((train) => {
      const attributes = train.attributes;
      attributes.geometry = train.geometry;
      attributes.layer = train.layer;

      // trains performance based data
            let trainPerformance = attributes.TRAIN_PERFORMANCE_X;
            if (trainPerformance === 'Late_Red' || trainPerformance === 'Late_Yellow') {
              trainPerformance = trainPerformance
                .toLowerCase()
                .replaceAll(' ', '-');
              if (!this.trainsbyPerformance[trainPerformance]) {
                this.trainsbyPerformance[trainPerformance] = {
                  title: attributes.TRAIN_PERFORMANCE_X === 'Late_Red' ? 'Late Trains' : 'Delayed Trains',
                  inicidents: [],
                };
              }
              this.trainsbyPerformance[trainPerformance]['inicidents'].push(
                attributes
              );
            }
    });

     // train performance based data
          const keys = Object.keys(this.trainsbyPerformance);
          keys.forEach((key) => {
            this.trainsbyPerformanceArr.push(this.trainsbyPerformance[key]);
          });
          if (this.trainsbyPerformanceArr.length === 4) {
           this.trainPerformanceChange(this.trainsbyPerformanceArr);
          }

  });
}

trainPerformanceChange(val) {
    this._trainbyPerformanceObs.next(val);
  }

  humpYardChange(val) {
    this._humpYardObs.next(val);
  }

   getRegions(): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http
      .get(environment.appConfig.api.deptSettingsApi + "/desk/dymcregions", {
        headers: headers
      })
      .pipe(
        map(
          response => {
            return response;
          },
          (error: any) => {
            console.error(
              "Error at getRegions() in globalService.",
              error
            );
          }
        )
      );
  }

  getZones(region:String): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http
      .get(environment.appConfig.api.deptSettingsApi + "/desk/deskdivision/" + region, {
        headers: headers
      })
      .pipe(
        map(
          response => {
            return response;
          },
          (error: any) => {
            console.error(
              "Error at getRegions() in globalService.",
              error
            );
          }
        )
      );
  }
 
  getSubdivisions(zone: String): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
// .get(environment.appConfig.api.deptSettingsApi + "/desk/dymcregion/"+region+"/subdivision/"+zone, {
      console.log("sub division url..."+ environment.appConfig.api.deptSettingsApi + "/desk/subdivision/"+zone);
    return this.http
      .get(environment.appConfig.api.deptSettingsApi + "/desk/subdivision/"+zone, {
        headers: headers
      })
      .pipe(
        map(
          response => {
            return response;
          },
          (error: any) => {
            console.error(
              "Error at getSubdivisions() in GlobalService.",
              error
            );
          }
        )
      );
  }

  refreshBanner(libId: 'gis'|'dync'){
    console.log("refreshing banner");
    const featureData = from(this.gis_tcisLayer.queryFeatures());
    featureData.pipe(take(1)).subscribe((response) => {
      console.log("response received")
      console.log(response)
    if (libId && libId === 'gis') {

      const inicidentsbyStatus = {};
      const inicidentsbyStatusArr = [];
      response.features.forEach((inicident) => {
        const attributes = inicident.attributes;
        attributes.geometry = inicident.geometry;
        attributes.layer = inicident.layer;
      // INICIDENT status based data
      let inicidentStatus = attributes.INCIDENT_STATUS_X;
      inicidentStatus = inicidentStatus
        .toLowerCase()
        .replaceAll(' ', '-');
      if (!inicidentsbyStatus[inicidentStatus]) {
        inicidentsbyStatus[inicidentStatus] = {
          title: attributes.INCIDENT_STATUS_X,
          inicidents: [],
        };
      }
      inicidentsbyStatus[inicidentStatus]['inicidents'].push(
        attributes
      );


      
    });
    // to add un fetched status values to zero
    this.inicidentStatuses.forEach((obj) => {
      if (!inicidentsbyStatus[obj.value]) {
        inicidentsbyStatus[obj.value] = {
          title: obj.title,
          inicidents: [],
        };
      }
    });

    // INICIDENT status based data
    const keys = Object.keys(inicidentsbyStatus);
    keys.sort().forEach((key) => {
      if(key !== 'closed')
      inicidentsbyStatusArr.push(inicidentsbyStatus[key]);
    });

     // fix title of Cleared in Service for Display purposes
     if (inicidentsbyStatusArr[0].title === 'Cleared In Service') {
      inicidentsbyStatusArr[0].title = 'CIS';
    }
    console.log(inicidentsbyStatusArr);
  this._gis_inicidentbyStatusObs.next(inicidentsbyStatusArr);
  }
  })
  }
  
}
