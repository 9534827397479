import { MapConstants } from "../constants/map-constants";
import { GISLayerDefinition } from "csx-gis";

export const LAYER_DEFINITIONS = new Map<string, GISLayerDefinition[]>([
 
  [MapConstants.TCIS_INICIDENTS, [] as GISLayerDefinition[]],
  [MapConstants.BATTERY_MODE, [] as GISLayerDefinition[]],
  [MapConstants.RR_OWNER, [] as GISLayerDefinition[]],
  [MapConstants.BATTERY_HEALTH, [] as GISLayerDefinition[]],
  [MapConstants.LAST_REPORTED, [] as GISLayerDefinition[]],
  [MapConstants.EOT_VENDORS, [] as GISLayerDefinition[]],
 
   [MapConstants.TRAIN, [] as GISLayerDefinition[]],
    [MapConstants.TRACK_AUTHORITY, [] as GISLayerDefinition[]],
     [MapConstants.SO_CURRENT_DAY, [] as GISLayerDefinition[]],
]);
