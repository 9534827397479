import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Location, LocationStrategy, HashLocationStrategy, CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DyncSharedDataService } from '../app/dync-ng/dync-shared-data.service';

/* CSX-SPECIFIC */
import { AppUser, CsxNgModule, CsxNgTemplatesModule, CsxInterceptor } from 'csx-ng';
import { CsxSecurityModule, SecurityConfigInjectionToken } from '@csx/security';
import { SecConfig } from './security/security.config';

/* PRIME NG */
import {
  CalendarModule,
  DropdownModule,
  InputTextModule,
  MenubarModule,
  TabMenuModule,
  TieredMenuModule,
  MessageModule,
  MessagesModule,
  TableModule,
  TreeTableModule,
  OverlayPanelModule,
  DialogModule,
  TabViewModule,
  ChartModule,
  SidebarModule,
  CheckboxModule,
  PanelModule,
  PaginatorModule,
  ButtonModule,
  MultiSelectModule,
  MegaMenuModule,
  MenuModule
} from 'primeng';
import { BlockUIModule } from 'primeng/blockui';

/* APP-SPECIFIC */
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { WindowRef } from './windowref';
import { AppTemplateComponent } from './app-template/app-template.component';
import { AppHttpInterceptor } from './services/appHttpInterceptor.service';
// import { LoaderService } from './tcis-common/services/loader.service';
import { LayerManagerService, MapManagerService, MapConfigInjectionToken } from 'csx-gis';
import { environment } from 'src/environments/environment';
import { CsxGisModule } from 'csx-gis';
import { MAP_CONFIG } from '../config/map-config';
import { GisNgLibModule } from 'gis-ng-lib';
import { TcisDyncNgLibModule } from 'dync-ng-lib';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GlobalMapService} from './tcis-common/services/global-map.service';
import { personSearchService } from './tcis-common/services/person-search.service';
import { carIncidentService } from './tcis-common/services/car-incident.service.js';
import {HomeComponent} from './home/home.component';
import {TcisCommonModule} from './tcis-common/tcis-common.module';
import {LocalStorageService, NgxWebstorageModule} from 'ngx-webstorage';

@NgModule({
  declarations: [AppComponent, AppTemplateComponent, HomeComponent],
  imports: [
    NgxWebstorageModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    routing,
    CsxNgModule,
    CsxNgTemplatesModule,
    CsxSecurityModule.initialize(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule,
    GisNgLibModule.forRoot(environment),
    TcisDyncNgLibModule.forRoot(environment),
    TcisCommonModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    MenubarModule,
    TabMenuModule,
    TieredMenuModule,
    MessageModule,
    MessagesModule,
    TableModule,
    TreeTableModule,
    OverlayPanelModule,
    DialogModule,
    TabViewModule,
    ChartModule,
    SidebarModule,
    CheckboxModule,
    PanelModule,
    PaginatorModule,
    ButtonModule,
    MultiSelectModule,
    MegaMenuModule,
    MenuModule,
    CsxGisModule
  ],
  providers: [
    AppUser,
    WindowRef,
    DatePipe,
    DyncSharedDataService,
    personSearchService,
    carIncidentService,
    {
      provide: 'globalMapService',
      useClass: GlobalMapService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsxInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    {
      provide: MapConfigInjectionToken,
      useValue: MAP_CONFIG
    },
    {
      provide: 'environment',
      useValue: environment
    },
    [
      Location,
      {provide: LocationStrategy, useClass: HashLocationStrategy},
      {provide: SecurityConfigInjectionToken, useValue: SecConfig}
    ]
  ],
  bootstrap: [AppComponent],
  exports: [
    HttpClientModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
