import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { NotAuthorizedComponent } from 'csx-ng';
import {AppInfoComponent} from './tcis-common/app-info/app-info.component';


export const routing = RouterModule.forRoot(
  [
    {
      path : '',
      component : HomeComponent
    },
    { path: 'notauthorized',
      component: NotAuthorizedComponent,
      data: {
        adminEmail: 'app-admin@csx.com'
      }
    },
    {
      path: 'app-info',
      component: AppInfoComponent
    },
    {
      path: 'wknd-list',
      loadChildren: './wknd-list/wknd-list.module#WkndListModule'
    },
     {
      path: 'gis-ng',
      loadChildren: './gis-ng/gis-ng.module#GisNgModule'
    },
    {
      path: 'dync-ng',
      loadChildren: './dync-ng/dync-ng.module#DyncNgModule'
    },
    {
      path: 'call-dashboard',
      loadChildren: './call-dashboard/call-dashboard.module#CallDashboardNgModule'
    },
    {
      path: 'jumper-log',
      loadChildren: './jumper-log/jumper-log.module#JumperNgModule'
    },
    {
      path: 'call-availability',
      loadChildren: './call-availability/call-availability.module#CallAvailablityNgModule'
    }
  ],
  { useHash: true }
);
