import { SecurityConfig, SecurityImplementation } from "@csx/security"
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';
 
location: Location;

export const SecConfig: SecurityConfig = {
  
  implementation: SecurityImplementation.Oidc,
  groups: [
    // A user in the CSX-Technology role will become a member of the TechnologyUser group
    { name: 'TechnologyUser', roles: ['CSX-Technology'] }
  ],
  securityDomains: environment.appConfig.security.securityDomains,
  configuration: {
    clientId: environment.appConfig.security.clientId,
    autoConfigUrl: environment.appConfig.security.autoConfigUrl,
    autoLogin: true,
    silentRenew: true,
    routes: {
      logoutUrl: location.origin + '/unauthorized',
      redirectUrl: location.origin,
      silentRenewUrl: location.origin + '/assets/admin/silent-renew.html'
    },
    scopes: ['profile', 'email', 'phone', 'address']
  }
};