import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-info',
  templateUrl: './app-info.component.html',
  styleUrls: ['./app-info.component.css']
})
export class AppInfoComponent implements OnInit {
  appConfig = environment.appConfig;
  hostConfig = environment.hostConfig;
  dependencies = environment['dependencies'];
  csxgis_version = environment['dependencies']['csx-gis'];
  constructor() { }

  ngOnInit(): void {
  }

}
