<csx-header-with-menu-template>
    <page-header *ngIf="showCSXHeader">
        <csxheader [model]="headerModel">
            <ng-template xTemplate="notifiactionPanel">
               
                <p-table
                  #notificationTable
                  id="notificationTable"
                  styleClass="x-selectable-row"
                  [columns]="notificationTableColumns"
                  [value]="notifications"
                  selectionMode="single"
                  [(selection)]="selectedNotification"
                  (onRowSelect)="onNotificationSelected($event)"
                  [paginator]="true"
                  [rows]="5"
                  [responsive]="true"
                  [showCurrentPageReport]="true"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                >
                  <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                      <td *ngFor="let col of columns">
                        <div class="notification-row">
                          <div class="notification-col-flex-start">
                            <div class="notification-field-1" style="font-size: 12px;">
                              {{rowData[col.field[3]]}} - {{rowData[col.field[2]]}}
                            </div>
                            <div
                              class="notification-field-2"
                              *ngIf="rowData[col.field[0]]"
                            >
                            {{ rowData[col.field[0]] }}
                            </div>
                          </div>
                          <div
                            class="notification-col-flex-end"
                          >
                        <!--    <p-button
                              label="Acknowledge"
                              styleClass="notification-link"
                              (click)="onNotificationAcknowledged(rowData)"
                            ></p-button> -->
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                      <td [attr.colspan]="columns?.length">No records found</td>
                    </tr>
                  </ng-template>
                </p-table>
              </ng-template>
        </csxheader>
    </page-header>
    <page-menu *ngIf="router.url != '/notauthorized'">
       
        <p-tieredMenu [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #menu [model]="items" [popup]="true"></p-tieredMenu>
        <button #btn type="button" pButton icon="pi pi-fw pi-caret-down" label="{{headerModel.appLongName}}" (click)="menu.toggle($event)"  iconPos="right"></button>
        <button #btn type="button" pButton  label="Jumper Log" (click)="openJumperLog($event)"></button>
        <tcis-ticket-banner *ngIf="showBanner" [libId]="headerModel.appLongName == 'GIS Dashboard' ? 'gis':'dync'" style="position: absolute;z-index: 999;"></tcis-ticket-banner>
    </page-menu>
    <page-content>
        <div class="content-wrapper">
            <router-outlet></router-outlet>
        </div>
    </page-content>
    <page-footer>
        <div id="footer">
            2023 CSX Corporation Inc. All rights reserved.  <span style="float:right;"><b>Logged in:</b>{{loggedinTime | date : "medium"}}</span>
        </div>
    </page-footer>
</csx-header-with-menu-template>