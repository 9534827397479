import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthenticationService} from '@csx/security';
import { environment } from '../../environments/environment';

declare var gtag: Function;

@Injectable({
    providedIn: 'root'
  })
export class AnalyticsService {

  addAnalyticsJS = true;

  constructor(private router: Router, private authService: AuthenticationService) {}

  public init() {  

    if (this.addAnalyticsJS) {
      try {
        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = environment.appConfig.googleAnalyticsURL + environment.appConfig.googleAnalyticsTrackingId;
        document.head.appendChild(script1);
        
        const script2 = document.createElement('script');
        script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '` + environment.appConfig.googleAnalyticsTrackingId + `', {'send_page_view': false});`;

        document.head.appendChild(script2);

        if (typeof this.authService.user.racf !== 'undefined' && this.authService.user.racf) {
            gtag('set', {'user_id': '' +  this.authService.user.racf  + ''});
            
            //add user id as custom dimension
            gtag('config', environment.appConfig.googleAnalyticsTrackingId, {
            'custom_map': {
                'dimension1': 'userIdDimension'
            }
            });
            gtag('event', 'userIdDimension_event', {'userIdDimension': this.authService.user.racf});
        };
          
      } catch (ex) {
        console.error('Error appending google analytics');
        console.error(ex);
      }

      this.addAnalyticsJS = false;
    }

    this.listenForRouteChanges();
  }

  private listenForRouteChanges() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
          gtag('config', environment.appConfig.googleAnalyticsTrackingId, {
            'page_path': event.url.split("?")[0],
          });
      }
    });
  }

  public event(eventName: string, params: {}) {
    gtag('event', eventName, params);
  }
}