import { GroupsAllowed } from "@csx/security";
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  directionsApi = environment.appConfig.api.directionsApi;

  constructor() {}

  ngOnInit() {}
}
