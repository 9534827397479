import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoaderService } from '../tcis-common/services/loader.service';

@Injectable({
    providedIn: 'root'
})
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(private loaderService: LoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.hideLoader();
                } else {
                    this.showLoader();
                }
                return event;
            }), catchError(err => {
                // event is a response error
                console.error(err);
                this.hideLoader();
                return throwError(err);
            })
        );

    }

    hideLoader() {
        this.loaderService.hide();
    }

    showLoader() {
        this.loaderService.show();
    }
}
