import { GISMapService, LAYER_TYPES } from "csx-gis";
import { environment } from "../environments/environment";

export const MAP_LAYERS: GISMapService[] = [
   {
    id: "tcis-map-service",
    url: environment.appConfig.map.tcis,
    type: LAYER_TYPES.MAP_IMAGE_LAYER
  },
  {
    id: "cache_rail",
    type: LAYER_TYPES.TILE_LAYER,
    url: environment.appConfig.map.railCache,
    maxScale: 9028,
    minScale: 18489298
  }
];
