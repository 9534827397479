<div class="app-info-box">

  <h1>csx-gis version:</h1>
  <pre>{{ this.csxgis_version }}</pre>

  <h1>app-info hostConfig:</h1>
  <pre>{{ this.hostConfig | json }}</pre>

  <h1>app-info appConfig:</h1>
  <pre>{{ this.appConfig | json }}</pre>
</div>
