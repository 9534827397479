import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppInfoComponent} from './app-info/app-info.component';
import {LoaderComponent} from './loader/loader.component';
import {TcisTicketBannerComponent} from './tcis-ticket-banner/tcis-ticket-banner.component';
import { SearchWidgetComponent } from './search-widget/search-widget.component';
import { TcisSearchPersonComponent } from './tcis-search-person/tcis-search-person.component';
/* PRIME NG */
import {
  CalendarModule,
  DropdownModule,
  InputTextModule,
  MenubarModule,
  MessageModule,
  MessagesModule,
  TableModule,
  TreeTableModule,
  OverlayPanelModule,
  DialogModule,
  TabViewModule,
  ChartModule,
  SidebarModule,
  CheckboxModule,
  PanelModule,
  PaginatorModule,
  ButtonModule,
  MultiSelectModule,
  MegaMenuModule,
  MenuModule, SharedModule,
} from 'primeng';
import { BlockUIModule } from 'primeng/blockui';
import {LoaderService} from './services/loader.service';
import {EsriMapComponent} from 'csx-gis';
import {Observable} from 'rxjs';
import { DropDownComponent } from './form-elements/drop-down/drop-down.component';
import { TypeaheadComponent } from './form-elements/typeahead/typeahead.component';
import { SingleLineTextInputComponent } from './form-elements/single-line-text-input/single-line-text-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PrintReportComponent } from '../tcis-common/print-report/print-report/print-report.component';

export interface AbstractGlobalMapService {
  gis_featureLayer: typeof __esri.FeatureLayer;
  gis_query: typeof __esri.Query;
  gis_mapIsReady$: Observable<any>;
    dync_mapIsReady$: Observable<any>;
  gis_mapInstance: EsriMapComponent;
  gis_inicidentbyStatusObs: Observable<any>;
  gis_notificationObs: Observable<any>;
  gis_incidentData: Observable<any>;
  gis_rightPanelList: Observable<any>;
  gis_rightPanelInicident: Observable<any>;
  dync_featureLayer: typeof __esri.FeatureLayer;
  dync_query: typeof __esri.Query;
  dync_mapInstance: EsriMapComponent;
  dync_inicidentbyStatusObs: Observable<any>;
  dync_incidentData: Observable<any>;
  dync_rightPanelList: Observable<any>;
  dync_rightPanelInicident: Observable<any>;
  setMapInstance(mapInstance, libId: 'gis'|'dync'): void;
  listSelected(incidentList, libId: 'gis'|'dync'): void;
  inicidentSelected(incident, libId: 'gis'|'dync'): void;
  getLayerQueryString(queryObj): string;
  onMapClick(mapElemList): void;
  onMapClickDync(mapElemList): string;
  updateBannerDync(queryObj): void;
  refreshBanner(libId: 'gis'|'dync') : void;
  getRegions(): Observable<any>;
  getCallList(): Observable<any>;
  getZones(region:String): Observable<any>;
  getSubdivisions(zone: String): Observable<any>;
}

@NgModule({
  declarations: [
    AppInfoComponent,
    LoaderComponent,
    TcisTicketBannerComponent,
    DropDownComponent,
    TypeaheadComponent,
    SingleLineTextInputComponent,
    SearchWidgetComponent,
    TcisSearchPersonComponent,
    PrintReportComponent

  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    TableModule,
    TreeTableModule,
    OverlayPanelModule,
    DialogModule,
    TabViewModule,
    ChartModule,
    SidebarModule,
    CheckboxModule,
    PanelModule,
    PaginatorModule,
    ButtonModule,
    MultiSelectModule,
    MegaMenuModule,
    MenuModule,
    BlockUIModule
  ],
  exports: [
    AppInfoComponent,
    LoaderComponent,
    TcisTicketBannerComponent,
    SingleLineTextInputComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownComponent,
    SearchWidgetComponent,
    TcisSearchPersonComponent
  ],
  providers: [
    LoaderService
  ]
})
export class TcisCommonModule {}
