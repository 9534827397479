import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Feature} from '../../interfaces/tcis/tcis-tickets-features';
import {Observable} from 'rxjs';
import {AbstractGlobalMapService} from '../tcis-common.module';
import * as FileSaver from 'file-saver';
import { carIncidentService } from '../services/car-incident.service';

@Component({
  selector: 'tcis-ticket-banner',
  templateUrl: './tcis-ticket-banner.component.html',
  styleUrls: ['./tcis-ticket-banner.component.scss']
})
export class TcisTicketBannerComponent implements OnInit, OnDestroy {
  @Input() libId: 'gis'|'dync';
  features: Feature[];
  displayTcisTicketDialog = false;
  displayCarIncidentDialog = false;
  inicidentsbyStatusArr$: Observable<any>;
  selectedTickets:any;
  selectedTicket:any;
  ticketTimer: any;
  test: any;
  notificationBanner:any;
  carIncidents:any;
  selectedCarIncident:any;
  index: number;
  inicidentsbyStatusArr: any;

  constructor(
    @Inject('globalMapService') private globalMapService: AbstractGlobalMapService,
    private carIncidentService:carIncidentService
  ) {}

  ngOnInit() {
    switch (this.libId) {
      case 'gis': {
        this.inicidentsbyStatusArr$ = this.globalMapService.gis_inicidentbyStatusObs;
        break;
      }
      case 'dync': {
        this.inicidentsbyStatusArr$ = this.globalMapService.dync_inicidentbyStatusObs;
        break;
      }
      default: {
        throw new Error('Unrecognized libId. tcis-ticket-banner requires libId');
      }
    }
    this.index=0;
    this.inicidentsbyStatusArr$.subscribe((resp)=>{
      this.inicidentsbyStatusArr=resp;
    })
    this.globalMapService.gis_notificationObs.subscribe((notification:any[])=>{
      this.notificationBanner={inicidents:notification["notificationBannerData"]};
    })
    //setInterval(()=>this.refresh(),180000);
   this.carIncidentService.retrieveCarIncidents().subscribe((resp)=>{
     this.carIncidents = resp;
    });
  }

  ngOnDestroy() {
    clearInterval(this.ticketTimer);
  }
  previousValue(){
    if(this.index>0){
      this.index=this.index-1;
    }
    else this.index=0;
  }
  nextValue(){
    if(this.index<this.inicidentsbyStatusArr.length){
      this.index=this.index+1;
    }
    else this.index=this.inicidentsbyStatusArr.length-1;
  }
  refresh(){
    this.globalMapService.refreshBanner('gis');
  }
  public showDialog(entry: any) {
    this.selectedTickets = entry;
    this.displayTcisTicketDialog = true;


   /* this.globalMapService.listSelected({
      displayHeader: 'Inicident Status',
      selectedInicidentList: entry
    }, this.libId);*/
  } 


  public showCarDialog(entry: any) {
    this.displayCarIncidentDialog = true;
  } 

  onTicketSelected(){
  this.displayTcisTicketDialog = false;
  if(this.libId === 'gis'){
     this.globalMapService.gis_mapInstance.goTo({
      target: this.selectedTicket.geometry,
      zoom: 10,
    }).then(() => {
    });
  //  this.globalMapService.gis_mapInstance.goToPoint(this.selectedTicket.geometry).then(() => {});
    this.globalMapService.inicidentSelected(this.selectedTicket,'gis');
  }
  else if(this.libId === 'dync') {
    this.globalMapService.dync_mapInstance.goToPoint(this.selectedTicket.geometry).then(() => {
    });
  }
  }
  onCarIncidentSelected(){
    console.log(this.selectedCarIncident)
  }

 
  exportExcel() {
    const excelData = [];
    for(let incident of this.selectedTickets?.inicidents){
      excelData.push({"Ticket Id":incident.INCIDENT_I,"Opened Date":new Date(incident.ENTERED_D),"Type":incident.ASSET_TYPE_X,"Asset":incident.ASSET_X," Subdivision Name":incident.SUBDIVISION_N,})
    }
          import("xlsx").then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(excelData);
              const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
              const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
              this.saveAsExcelFile(excelBuffer, "incidents");
          });
      }
  
      exportCarExcel() {
        const excelData = [];
        for(let incident of this.carIncidents){
          excelData.push({"Ticket Id":incident.incidentId,"Reported Date":new Date(incident.repDT),"Car Description":incident.carDescr,"Last Reported By":incident.lastT0chdRepoByRacf,"Last Reported Location":incident.lastT0chdLocName,"addInfo":incident.addInfo})
        }
              import("xlsx").then(xlsx => {
                  const worksheet = xlsx.utils.json_to_sheet(excelData);
                  const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                  const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                  this.saveAsExcelFile(excelBuffer, "carIncidents");
              });
          }

      saveAsExcelFile(buffer: any, fileName: string): void {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
      
}
